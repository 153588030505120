import React from 'react';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class OsuGiAdvert extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Osu-Gi Advertisement, Australia 1990</h1>
                <StaticImage src="../../images/osugi/ResizedImage600805-8602636.jpg" alt="Osu-Gi Advertisement"/>
            </Layout>);
    }
}

export default OsuGiAdvert;
